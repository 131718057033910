import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Theme } from "types";

enum ThemeColors {
  MAIN = "--main-color",
  TEXT = "--text-color",
  ACCENT_TEXT = "--accent-text-color",
  PRIMARY = "--primary-color",
  ALERT = "--alert-color",
  PLACEHOLDER = "--placeholder-color",
}

type ThemeState = {
  theme: Theme;
};

const initialState: ThemeState = {
  theme: Theme.LIGHT,
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
  },
});

const themeReducer = themeSlice.reducer;
const { setTheme } = themeSlice.actions;

export { themeReducer, setTheme, Theme, ThemeColors };
