import { PropsWithChildren } from "react";

import styles from "./Error.module.css";

type ErrorProps = PropsWithChildren & React.HTMLAttributes<HTMLSpanElement>;

const Error = ({ children, className }: ErrorProps) => {
  return <span className={`${styles.error} ${className}`}>{children}</span>;
};

export { Error };
