import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReduxModal } from "components";
import { Footer, Header, Title } from "./components";
import { useAppDispatch, useAppSelector } from "store";
import { setTheme } from "store/themeSlice";
import { AppRoutes } from "routes";
import { Theme } from "types";

import styles from "./Layout.module.css";

type LayoutProps = {
  isProtected?: boolean;
};

const Layout = ({ isProtected = false }: LayoutProps) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.authReducer.user);

  useEffect(() => {
    if (!user) return;

    const { locale, theme } = user;

    locale && i18n.changeLanguage(locale);
    if (theme && Object.values<string>(Theme).includes(theme)) {
      dispatch(setTheme(theme as Theme));
    }
  }, [user]);

  useEffect(() => {
    if (isProtected && !user) {
      navigate(AppRoutes.HOME);
    }
  }, [isProtected, user, navigate]);

  return (
    <div className={styles.layout}>
      <Header>
        <Title />
      </Header>

      <main className={styles.outletWrapper}>
        <Outlet />
      </main>

      <ReduxModal />

      <Footer />
    </div>
  );
};

export { Layout };
