import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  SelectHTMLAttributes,
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { noop } from "utils";
import { Error } from "../Error";
import { FieldProps } from "../types";

import styles from "../Form.module.css";

export type Option = {
  id?: string | number;
  label: string | number;
  value: string | number;
};

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> &
  FieldProps & {
    options: Option[] | string[] | number[];
    placeholder?: string;
  };

const SelectComponent: ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectProps
> = (
  {
    options,
    label,
    isError,
    errorMessage,
    isErrorPlaceholder = true,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    className,
    ...selectProps
  },
  forwardedRef,
) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useRef<HTMLSelectElement>(null);

  // to be able to use ref with forwardedRef
  useImperativeHandle(forwardedRef, () => ref.current as HTMLSelectElement);

  useEffect(() => {
    setIsActive(!!ref.current?.value);
  }, [ref]);

  const optionsArray: Option[] = useMemo(() => {
    return options.map((option, i) => {
      if (typeof option === "string" || typeof option === "number")
        return {
          id: i,
          label: option,
          value: option,
        };

      return {
        id: option.id || i,
        ...option,
      };
    });
  }, [options]);

  const onInputChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setIsActive(!!e.target.value);

    onChange(e);
  };

  const onInputFocus: FocusEventHandler<HTMLSelectElement> = (e) => {
    setIsActive(true);

    onFocus(e);
  };

  const onInputBlur: FocusEventHandler<HTMLSelectElement> = (e) => {
    if (!e.target.value) setIsActive(false);

    onBlur(e);
  };

  return (
    <div className={className}>
      <label
        className={`
          ${styles.label} 
          ${isError && styles.errorLabel} 
          ${isActive && styles.activeLabel}
        `}
      >
        <span
          className={`
            ${styles.labelText}
            ${isError && styles.errorLabelText}
          `}
        >
          {label}
        </span>
        <select
          className={styles.input}
          ref={ref}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          {...selectProps}
        >
          {optionsArray.map(({ id, label, value }) => (
            <option value={value} key={id}>
              {label}
            </option>
          ))}
        </select>
      </label>
      {isErrorPlaceholder && <Error>{isError && errorMessage}</Error>}
    </div>
  );
};

const Select = forwardRef(SelectComponent);

export { Select };
