import { createApi } from "@reduxjs/toolkit/query/react";

import { User } from "types";
import { getCommonBaseQuery } from "utils";
import { setProfile } from "./authSlice";

const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

// TODO: figure out something with "onQueryStarted" and how to reuse it
const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: getCommonBaseQuery(baseUrl),
  endpoints: (builder) => ({
    getProfile: builder.query<User, void>({
      query: () => "",
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProfile(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    updateProfile: builder.mutation<User, Partial<User>>({
      query: (body) => ({
        url: "",
        method: "PUT",
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setProfile(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

const { useGetProfileQuery, useUpdateProfileMutation } = profileApi;

export { profileApi, useGetProfileQuery, useUpdateProfileMutation };
