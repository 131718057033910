import Modal from "react-modal";

const style: Modal.Styles = {
  overlay: {
    backgroundColor: "color-mix(in srgb, var(--text-color) 50%, transparent)",
    color: "var(--text-color)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",

    padding: "20px 20px 30px",
    width: "50%",
    minWidth: "350px",
    backgroundColor: "var(--primary-color)",
    borderRadius: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

export { style };
