import { useTranslation } from "react-i18next";
import { monthNames } from "utils";

import styles from "./Header.module.css";

type HeaderProps = {
  date: Date;
};

const Header = ({ date }: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <h4 className={styles.headerWrapper}>
      <span>{t(`months.${monthNames[date.getMonth()]}`)}</span>
      <span>{String(date.getDate())}</span>
    </h4>
  );
};

export { Header };
