import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { useAppSelector, useAppDispatch } from "store";
import { closeModal } from "store/modalSlice";
import { style } from "./ModalStyles";
import { modalsList } from "./modalsList";

import themes from "Themes.module.css";
import styles from "./Modal.module.css";
import { useMemo } from "react";

const ReduxModal = () => {
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.themeReducer.theme);
  const dispatch = useAppDispatch();
  const { isOpen, modalId } = useAppSelector((state) => state.modalReducer);
  const onRequestClose = () => dispatch(closeModal());

  const { titleKey, modalBody } = useMemo(
    () => modalsList[modalId],
    [modalsList, modalId],
  );

  return (
    <ReactModal
      style={style}
      portalClassName={`${themes[theme]}`}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
    >
      <div className={styles.modalBody}>
        <div className={styles.modalHeader}>
          <h3 className={styles.title}>{t(titleKey)}</h3>
          <AiOutlineCloseCircle
            size={20}
            className={styles.closeIcon}
            onClick={onRequestClose}
          />
        </div>

        {modalBody}
      </div>
    </ReactModal>
  );
};

export { ReduxModal };
