export const monthNames = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const emailRegexp = new RegExp(
  "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
  "g",
);

type GetRandomInt = (min: number, max: number) => number;

export const getRandomInt: GetRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type ExtendString = (
  str: string,
  newLength: number,
  extendS?: string,
) => string;

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

// adds symbols to the end of str to make it with enough length
export const extendString: ExtendString = (
  str,
  newLength,
  extendStr = str[str.length - 1] || " ",
) => {
  if (str.length >= newLength) return str;

  const lengthDiff = newLength - str.length;
  if (extendStr.length > lengthDiff) return `${str}${extendStr}`;

  const strEnd = new Array(lengthDiff).fill(extendStr).join("");
  return `${str}${strEnd}`;
};

type SetOpacity = (hex: string, alpha: number) => string;

// adds alfa channel (opacity) to hex format color
export const setOpacity: SetOpacity = (hex, alpha) => {
  const hexColor = hex.length < 7 ? extendString(hex, 7) : hex;

  return `${hexColor}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, "0")}`;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

type GetAge = (dateString: string) => number;

export const getAge: GetAge = (dateString) => {
  if (!dateString) return 0;

  const today = new Date();
  const [month, day, year] = dateString.split("-");
  const birthDate = new Date(+year, +month, +day);
  const monthDiff = today.getMonth() - birthDate.getMonth();

  let age = today.getFullYear() - birthDate.getFullYear();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const minPasswordLength = 8;
