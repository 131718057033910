import { ReactElement, CSSProperties, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "store";
import { useSignInMutation, useSignUpMutation } from "store/authApi";
import { openModal } from "store/modalSlice";
import { AppRoutes } from "routes";
import { Button, Logo } from "components";
import { ReactComponent as AccountSvg } from "svg/account.svg";

import { ModalId } from "types";

import styles from "./Header.module.css";

type HeaderProps = {
  children?: ReactElement;
};

const svgSize = 50;

const Header = ({ children }: HeaderProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.authReducer.user);
  const { photoUrl } = user || {};
  const dispatch = useAppDispatch();

  const [, { isLoading: isSignInLoading }] = useSignInMutation({
    fixedCacheKey: "signIn",
  });
  const [, { isLoading: isSignUpLoading }] = useSignUpMutation({
    fixedCacheKey: "signUp",
  });

  const accountButtonStyle: CSSProperties = useMemo(() => {
    if (!photoUrl) return {};

    return {
      backgroundImage: `url(${photoUrl})`,
      width: `${svgSize + 6}px`,
      height: `${svgSize + 6}px`,
    } as CSSProperties;
  }, [photoUrl]);

  return (
    <header className={styles.header}>
      <button
        aria-label="home icon"
        className={styles.iconWrapper}
        // disabled={pathname === AppRoutes.HOME}
        onClick={() => navigate(AppRoutes.HOME)}
      >
        <Logo width={svgSize} height={svgSize} />
      </button>

      {children}

      {user && (
        <button
          aria-label="account icon"
          className={`${styles.iconWrapper} ${styles.accountBtn}`}
          disabled={pathname === AppRoutes.ACCOUNT}
          onClick={() => navigate(AppRoutes.ACCOUNT)}
          style={accountButtonStyle}
        >
          {!user.photoUrl && <AccountSvg width={svgSize} height={svgSize} />}
        </button>
      )}
      {!user && (
        <Button
          className={styles.loginBtn}
          variant="text"
          onClick={() => dispatch(openModal(ModalId.LOGIN))}
          disabled={isSignInLoading || isSignUpLoading}
        >
          {t(
            isSignInLoading || isSignUpLoading
              ? "common.loading"
              : "authForms.login",
          )}
        </Button>
      )}
    </header>
  );
};

export { Header };
