import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Emotion as EmotionType, ModalId } from "types";
import { Emotion } from "./components";
import { Error } from "components";
import { useAppDispatch } from "store";
import { openModal } from "store/modalSlice";
import { selectEmotion } from "store/emotionsSlice";
import { useGetJournalsQuery } from "store/journalApi";

import styles from "./EmotionsList.module.css";

type EmotionsListProps = { date?: Date };

const EmotionsList = ({ date = new Date() }: EmotionsListProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formattedDate: string = useMemo(() => {
    const offset = date.getTimezoneOffset();
    // to prevent timezone issues
    const offsetDate = new Date(date.getTime() - offset * 60 * 1000);
    return offsetDate.toISOString().split("T")[0];
  }, [date]);

  const {
    data: emotions = [],
    isError,
    isFetching,
    isLoading,
  } = useGetJournalsQuery({
    from: formattedDate,
    to: formattedDate,
  });

  const onEdit = (emotionId: EmotionType["id"]) => {
    dispatch(selectEmotion(emotionId));
    dispatch(openModal(ModalId.EMOTION_EDIT));
  };

  const onDelete = (emotionId: EmotionType["id"]) => {
    // TODO: implement remove emotion functionality when ready
  };

  return (
    <div className={styles.emotionsListWrapper}>
      {!isLoading &&
        !isFetching &&
        emotions.map((emotion) => (
          <Emotion
            emotion={emotion}
            key={emotion.id}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        ))}

      {(isLoading || isFetching) && <span>{t("common.loading")}</span>}

      <Error>{isError && t("common.error.somethingWrong")}</Error>
    </div>
  );
};

export { EmotionsList };
