import { ButtonHTMLAttributes } from "react";

import { ReactComponent as ArrowSvg } from "svg/arrow.svg";

import styles from "./ArrowButton.module.css";

const defaultArrowSize = 30;

type ArrowButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  direction: "left" | "right";
  size?: number;
};

const ArrowButton = ({
  direction,
  size = defaultArrowSize,
  className,
  ...props
}: ArrowButtonProps) => {
  return (
    <button
      className={`
        ${styles.button} 
        ${className}
      `}
      {...props}
    >
      <ArrowSvg
        width={size}
        height={size}
        className={`${styles.arrow} ${styles[direction]}`}
      />
    </button>
  );
};

export { ArrowButton };
