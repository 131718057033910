import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ModalId } from "types";

type ModalState = {
  isOpen: boolean;
  modalId: ModalId;
};

const initialState: ModalState = {
  isOpen: false,
  modalId: ModalId.LOGIN,
};

const modalSlice = createSlice({
  name: "modalSlice",
  initialState,
  reducers: {
    openModal: (state, { payload }: PayloadAction<ModalState["modalId"]>) => {
      state.isOpen = true;
      state.modalId = payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
    setModalId: (state, { payload }: PayloadAction<ModalState["modalId"]>) => {
      state.modalId = payload;
    },
  },
});

const modalReducer = modalSlice.reducer;
const { openModal, closeModal, setModalId } = modalSlice.actions;

export { modalSlice, modalReducer, openModal, closeModal, setModalId };
