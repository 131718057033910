import {
  ChangeEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useState,
  JSXElementConstructor,
  SVGProps,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { noop } from "utils";
import { Error } from "../Error";
import { FieldProps } from "../types";

import styles from "../Form.module.css";

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  FieldProps & {
    Icon?: JSXElementConstructor<SVGProps<SVGSVGElement>>;
    onIconClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  };

const iconSize = 25;

const InputComponent: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    isError,
    errorMessage,
    isErrorPlaceholder = true,
    Icon,
    onIconClick = noop,
    onChange = noop,
    className,
    ...inputProps
  },
  forwardedRef,
) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  // to be able to use ref with forwardedRef
  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);

  useEffect(() => {
    setIsActive(!!ref.current?.value);
  }, [ref]);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsActive(!!e.target.value);

    onChange(e);
  };

  return (
    <div className={className}>
      <label
        className={`
          ${styles.label} 
          ${isError && styles.errorLabel}
          ${isActive && styles.activeLabel}
        `}
      >
        <span
          className={`
            ${styles.labelText}
            ${isError && styles.errorLabelText}
          `}
        >
          {label}
        </span>
        <input
          ref={ref}
          className={styles.input}
          onChange={onInputChange}
          {...inputProps}
        />
        {!!Icon && (
          <button
            aria-label="input icon"
            className={styles.iconButton}
            onClick={onIconClick}
          >
            <Icon width={iconSize} height={iconSize} />
          </button>
        )}
      </label>
      {isErrorPlaceholder && <Error>{isError && errorMessage}</Error>}
    </div>
  );
};

const Input = forwardRef(InputComponent);

export { Input };
