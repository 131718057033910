import { AiOutlineClose } from "react-icons/ai";

import { Emotion as EmotionType } from "types";
import { ReactComponent as PencilSvg } from "svg/pencil.svg";

import styles from "./Emotion.module.css";

type EmotionProps = {
  emotion: EmotionType;
  onDelete: (id: EmotionType["id"]) => void;
  onEdit: (id: EmotionType["id"]) => void;
};

const Emotion = ({ emotion, onEdit, onDelete }: EmotionProps) => {
  const { id, emotionId, intensity } = emotion;

  return (
    <div className={styles.emotionWrapper}>
      <span>{emotionId}</span>
      <span>{intensity}</span>

      <div>
        {/* buttons are temporary disabled because we do not have delete and update API functionality */}
        <button disabled aria-label="edit emotion" onClick={() => onEdit(id)}>
          <PencilSvg width={20} height={20} />
        </button>
        <button
          disabled
          aria-label="delete emotion"
          onClick={() => onDelete(id)}
        >
          <AiOutlineClose size={20} />
        </button>
      </div>
    </div>
  );
};

export { Emotion };
