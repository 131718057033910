import { useMemo } from "react";
import { Day } from "./components";
import styles from "./Month.module.css";

type MonthProps = {
  date: Date;
};

const Month = ({ date }: MonthProps) => {
  const monthArray: Date[][] = useMemo(() => {
    return [...Array(date.getDate())].reduce(
      (month, _, day) => {
        const dayDate = new Date(date.valueOf());
        dayDate.setDate(day + 1);
        const dayOfWeek = dayDate.getDay();

        if (dayOfWeek === 1) {
          month.push([]);
        }

        month[month.length - 1].push(dayDate);

        return month;
      },
      [[]],
    );
  }, [date]);

  return (
    <div className={styles.monthWrapper}>
      <div className={styles.weeksWrapper}>
        {monthArray.map((week, i) => (
          <div className={styles.week} key={i}>
            {week.map((day) => (
              <Day date={day} key={day.valueOf()} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export { Month };
