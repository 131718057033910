import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppRoutes } from "routes";

import styles from "./Title.module.css";

const Title = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  // enum returns undefined in runtime so we need to use it with useMemo
  const titles: Partial<Record<AppRoutes, string>> = useMemo(() => {
    return {
      [AppRoutes.ACCOUNT]: t("appTitles.account"),
      [AppRoutes.CALENDAR]: t("appTitles.calendar"),
      [AppRoutes.DAY]: t("appTitles.day"),
      [AppRoutes.DIAGRAMS]: t("appTitles.diagrams"),
    };
  }, [t]);

  return <h1 className={styles.title}>{titles[pathname as AppRoutes]}</h1>;
};

export { Title };
