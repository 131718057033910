import {
  TouchEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ArrowButton, Month, Option, Paper, Select } from "components";
import { monthNames } from "utils";

import styles from "./CalendarForm.module.css";

type Coordinates = {
  x: number;
  y: number;
};

export type CalendarFormValues = {
  year: string;
  month: string;
};

type SetMonth = (direction: "prev" | "next") => void;

const now = new Date();
now.setMonth(now.getMonth() + 1); // go to the next month of date
now.setDate(0); // set day to 0 will set date to last day of previous (current in our case) month
const calendarStartYear = 2020;
const currentYear = now.getFullYear();
const currentMonth = now.getMonth();
const yearsDiff = currentYear - calendarStartYear;
const yearOptions: number[] = [...new Array(yearsDiff + 1)].map(
  (_, i) => calendarStartYear + i,
);
const defaultValues = {
  year: `${currentYear}`,
  month: `${currentMonth}`,
};

// TODO: add disabled logic for month arrow buttons
const CalendarForm = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(now);
  const [touchStart, setTouchStart] = useState<Coordinates>({ x: 0, y: 0 });
  const [touchEnd, setTouchEnd] = useState<Coordinates>({ x: 0, y: 0 });
  const { register, handleSubmit, setValue, getValues, watch } =
    useForm<CalendarFormValues>({
      defaultValues,
      mode: "onBlur",
    });

  const monthOptions: Option[] = useMemo(
    () =>
      monthNames.map((month, i) => ({
        label: t(`months.${month}`),
        value: i,
      })),
    [t],
  );

  const onFormSubmit: SubmitHandler<CalendarFormValues> = useCallback(
    (data) => {
      const { month, year } = data;
      const newDate = new Date(+year, +month + 2, 0);
      newDate.setDate(0);
      setDate(newDate);
      console.log({ month, year, m: monthNames[+month] });
    },
    [],
  );

  useEffect(() => {
    // submit form on change
    const subscription = watch(() => handleSubmit(onFormSubmit)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onFormSubmit]);

  const yearRegister = register("year", {
    required: t("common.error.required"),
  });

  const monthRegister = register("month", {
    required: t("common.error.required"),
  });

  const setMonth: SetMonth = (direction) => {
    const { month, year } = getValues();

    if (direction === "prev") {
      if (month === "0" && +year === calendarStartYear) return;

      if (month === "0") {
        setValue("year", `${+year - 1}`);
        setValue("month", "11");
        return;
      }

      setValue("month", `${+month - 1}`);
      return;
    }

    if (direction === "next") {
      if (month === "11" && +year === currentYear) return;

      if (month === "11") {
        setValue("year", `${+year + 1}`);
        setValue("month", "0");
        return;
      }

      setValue("month", `${+month + 1}`);
      return;
    }
  };

  const onWrapperTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    setTouchStart({
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    });
  };

  const onWrapperTouchEnd: TouchEventHandler<HTMLDivElement> = (e) => {
    setTouchEnd({
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY,
    });
  };

  useEffect(() => {
    const xDiff = Math.abs(touchStart.x - touchEnd.x);
    const yDiff = Math.abs(touchStart.y - touchEnd.y);

    if (xDiff > yDiff) {
      if (touchEnd.x < touchStart.x) setMonth("next");
      if (touchEnd.x > touchStart.x) setMonth("prev");
    }
  }, [touchEnd]);

  return (
    <div
      className={styles.calendarFormWrapper}
      onTouchStart={onWrapperTouchStart}
      onTouchEnd={onWrapperTouchEnd}
    >
      <Paper className={styles.calendarFormPaper}>
        <form className={styles.calendarForm}>
          <Select
            options={monthOptions}
            label={t("calendarForm.month")}
            isErrorPlaceholder={false}
            {...monthRegister}
          />
          <Select
            options={yearOptions}
            label={t("calendarForm.year")}
            isErrorPlaceholder={false}
            {...yearRegister}
          />
        </form>
      </Paper>

      <div className={styles.calendarControlsWrapper}>
        <ArrowButton
          className={styles.arrowButton}
          direction="left"
          aria-label="previous month"
          onClick={() => setMonth("prev")}
        />

        <Month date={date} />

        <ArrowButton
          className={styles.arrowButton}
          direction="right"
          aria-label="next month"
          onClick={() => setMonth("next")}
        />
      </div>
    </div>
  );
};

export { CalendarForm };
