import { SVGProps, useMemo, CSSProperties } from "react";

type LogoProps = SVGProps<SVGSVGElement> & {
  primaryColor?: string;
  secondaryColor?: string;
};

const Logo = ({ primaryColor, secondaryColor, ...props }: LogoProps) => {
  const primaryFillStyle: CSSProperties = useMemo(
    () => ({
      fill: primaryColor || "var(--main-color)",
    }),
    [primaryColor],
  );

  const secondaryFillStyle: CSSProperties = useMemo(
    () => ({
      fill: primaryColor || "var(--text-color)",
    }),
    [secondaryColor],
  );

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 250 250"
      {...props}
    >
      <g>
        <path
          d="M128.4,3C62.7,3,9.4,56.3,9.4,122c0,65.7,53.3,119,119,119s119-53.3,119-119C247.4,56.3,194.1,3,128.4,3z M122,225.3
		c-55.2,0-100-44.8-100-100s44.8-100,100-100c55.2,0,100,44.8,100,100S177.2,225.3,122,225.3z"
        />
      </g>
      <g id="DIARY">
        <g style={primaryFillStyle}>
          <path
            d="M121.7,11.3C57.1,11.3,4.6,62.8,4.6,126.2s52.4,114.9,117.1,114.9s117.1-51.4,117.1-114.9
			S186.4,11.3,121.7,11.3z M115.4,225.9c-54.3,0-98.4-43.2-98.4-96.5s44-96.5,98.4-96.5c54.3,0,98.4,43.2,98.4,96.5
			S169.8,225.9,115.4,225.9z"
          />
          <g>
            <g>
              <path
                d="M58.2,173.5c-2,0.2-4,0.3-5.9,0.3c-2,0-3.2-0.1-3.8-0.3c-0.5-0.2-0.8-0.5-0.8-0.9V152H41
					c-0.4,0-0.7-0.4-0.9-1.4c-0.3-0.9-0.4-2.2-0.4-4c0-1.8,0.2-3.4,0.6-4.8c0.4-1.4,0.9-2.1,1.5-2.1h26.1c0.5,0,0.8,0.4,1,1.3
					c0.2,0.9,0.3,2.2,0.3,3.9c0,1.7-0.2,3.3-0.5,4.8c-0.4,1.5-0.8,2.3-1.4,2.3h-6v20.7C61.1,173.1,60.2,173.3,58.2,173.5z"
              />
              <path
                d="M88.3,174.3c-2.7,0-5-0.2-6.9-0.7c-1.9-0.5-3.7-1.4-5.3-2.7c-3.2-2.5-4.9-7.4-4.9-14.6c0-3,0.4-5.7,1.3-7.9
					c0.9-2.2,2.1-4,3.7-5.4c3.1-2.6,7.1-3.9,12-3.9c2.5,0,4.7,0.2,6.6,0.7c1.9,0.5,3.7,1.3,5.3,2.5c3.4,2.4,5.1,7.1,5.1,14
					c0,6.2-1.5,10.7-4.6,13.6C97.7,172.8,93.6,174.3,88.3,174.3z M86.7,166c0.4,0.5,0.9,0.7,1.6,0.7c0.7,0,1.2-0.2,1.6-0.7
					c0.4-0.5,0.7-1.5,1-3c0.3-1.5,0.4-3.7,0.4-6.4c0-2.7-0.1-4.8-0.4-6.3c-0.3-1.5-0.6-2.4-1-2.9c-0.4-0.4-0.9-0.7-1.6-0.7
					c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.5-0.7,1.4-1,2.9c-0.3,1.5-0.4,3.6-0.4,6.3c0,2.7,0.1,4.9,0.4,6.4C86,164.6,86.4,165.6,86.7,166z
					"
              />
              <path
                d="M123.9,173.9h-11.4c-1.6,0-2.6-0.1-3.2-0.3c-0.5-0.2-0.8-0.6-0.8-1.1V142c0-0.8,0.4-1.4,1.1-1.8
					c0.7-0.4,1.9-0.6,3.7-0.6h10.8c6.2,0,10.4,1.2,12.8,3.6c2.3,2.4,3.5,6.8,3.5,13.2c0,6.4-1.2,10.9-3.7,13.5
					C134.2,172.6,130,173.9,123.9,173.9z M123.5,146.8c-0.8,0-1.3,0.1-1.5,0.2v19.5l1.8,0.2c1,0,1.7-0.8,2.2-2.3
					c0.5-1.5,0.7-4.2,0.7-8c0-3.8-0.3-6.3-0.8-7.6C125.4,147.5,124.6,146.8,123.5,146.8z"
              />
              <path
                d="M155.6,173.3c-0.9,0.4-2.6,0.5-4.9,0.5c-2.4,0-4.4-0.2-6-0.7c-1.6-0.4-2.4-1-2.4-1.7
					c0-0.8,1.4-5.7,4.3-14.8c2.9-9.1,4.5-14.3,4.9-15.7c0.2-0.4,1.5-0.8,3.9-1c2.4-0.3,5-0.4,7.8-0.4c2.8,0,4.3,0.5,4.6,1.4
					c6.1,20.2,9.1,30.4,9.1,30.8s-0.2,0.6-0.6,1c-0.9,0.7-2.7,1.1-5.4,1.1c-5.7,0-8.6-0.7-8.7-2l-0.3-3.1h-4.6l-0.2,2.7
					C157,172.3,156.5,173,155.6,173.3z M160.9,159.5l-0.7-8.7h-1.1l-0.8,8.7H160.9z"
              />
              <path
                d="M191.7,173.5c-2,0.3-3.9,0.4-5.9,0.4c-1.9,0-3.2-0.1-3.8-0.3c-0.6-0.2-0.9-0.6-0.9-1.1v-8.4
					c-6.4-13-9.5-20.1-9.5-21.2c0-0.7,1.1-1.4,3.2-2.1c2.2-0.7,4.4-1.1,6.8-1.1c3.1,0,4.7,0.9,4.9,2.7l1.3,13h0.5l0.9-12.6
					c0.1-0.9,1.2-1.6,3.4-2.2c2.2-0.6,4.3-0.8,6.3-0.8c2,0,3.4,0.3,4.1,0.9c0.8,0.6,1.2,1.2,1.2,1.8c0,0.6-3.2,7.9-9.7,21.7v8.4
					C194.6,172.9,193.6,173.2,191.7,173.5z"
              />
            </g>
            <g>
              <path
                d="M19.7,112.4c-1.3,0-3.1,0-5.5,0c-2.4,0-4-0.2-4.9-0.5C8.5,111.5,8,111,8,110.2V62.4c0-1.2,1.6-2.1,4.7-2.8
					c3.1-0.7,6.4-1,10-1c3.5,0,6.1,0.5,7.6,1.4c1.5,0.9,2.4,1.9,2.7,2.9l5.2,20h0.7l4.9-19.1c0.4-1.4,2-2.6,5-3.6
					c3-1,6.2-1.6,9.7-1.6c3.5,0,6.2,0.3,7.8,0.8c1.7,0.6,2.5,1.3,2.5,2.1v48.7c0,0.7-1.4,1.2-4.3,1.6c-2.9,0.4-6.4,0.6-10.5,0.6
					c-4.1,0-6.2-0.7-6.2-2.2c0-4,0.4-11.1,1.3-21.3h-0.7l-3.9,19.8c-0.2,0.8-0.8,1.3-1.9,1.7c-1.1,0.4-2.7,0.5-4.9,0.5
					c-2.2,0-3.5-0.2-4-0.5c-0.5-0.3-0.8-0.9-0.9-1.7l-4.1-19.8h-0.7c0.9,10.4,1.4,17.5,1.4,21.3C29.2,111.5,26,112.3,19.7,112.4z"
              />
              <path
                d="M100.4,113.1c-4.2,0-7.8-0.4-10.8-1.2c-3-0.8-5.8-2.2-8.3-4.2c-5.1-4-7.7-11.6-7.7-23
					c0-4.7,0.7-8.9,2.1-12.4c1.4-3.5,3.3-6.3,5.8-8.4c4.9-4,11.1-6.1,18.9-6.1c4,0,7.5,0.4,10.4,1.1c3,0.7,5.7,2,8.4,3.9
					c5.3,3.8,8,11.2,8,22c0,9.7-2.4,16.9-7.2,21.4C115.1,110.8,108.6,113.1,100.4,113.1z M97.9,100.1c0.6,0.7,1.4,1.1,2.5,1.1
					c1.1,0,1.9-0.4,2.5-1.1c0.6-0.7,1.1-2.3,1.5-4.7c0.4-2.4,0.6-5.7,0.6-10c0-4.3-0.2-7.6-0.6-9.9c-0.4-2.3-0.9-3.8-1.5-4.5
					c-0.6-0.7-1.4-1.1-2.5-1.1c-1.1,0-1.9,0.4-2.5,1.1c-0.6,0.7-1.1,2.2-1.5,4.5c-0.4,2.3-0.6,5.6-0.6,9.9c0,4.3,0.2,7.6,0.6,10
					C96.7,97.8,97.2,99.4,97.9,100.1z"
              />
              <path
                d="M157.6,113.1c-4.2,0-7.8-0.4-10.8-1.2c-3-0.8-5.8-2.2-8.3-4.2c-5.1-4-7.7-11.6-7.7-23
					c0-4.7,0.7-8.9,2.1-12.4c1.4-3.5,3.3-6.3,5.8-8.4c4.9-4,11.1-6.1,18.9-6.1c4,0,7.5,0.4,10.4,1.1c3,0.7,5.7,2,8.4,3.9
					c5.3,3.8,8,11.2,8,22c0,9.7-2.4,16.9-7.2,21.4C172.4,110.8,165.9,113.1,157.6,113.1z M155.1,100.1c0.6,0.7,1.4,1.1,2.5,1.1
					c1.1,0,1.9-0.4,2.5-1.1c0.6-0.7,1.1-2.3,1.5-4.7c0.4-2.4,0.6-5.7,0.6-10c0-4.3-0.2-7.6-0.6-9.9c-0.4-2.3-0.9-3.8-1.5-4.5
					c-0.6-0.7-1.4-1.1-2.5-1.1c-1.1,0-1.9,0.4-2.5,1.1c-0.6,0.7-1.1,2.2-1.5,4.5c-0.4,2.3-0.6,5.6-0.6,9.9c0,4.3,0.2,7.6,0.6,10
					C154,97.8,154.5,99.4,155.1,100.1z"
              />
              <path
                d="M213.5,112.4h-17.9c-2.5,0-4.1-0.2-5-0.5c-0.9-0.4-1.3-0.9-1.3-1.7V62.3c0-1.3,0.6-2.3,1.7-2.8
					c1.1-0.6,3-0.9,5.8-0.9h17.1c9.7,0,16.4,1.9,20.1,5.7c3.7,3.8,5.5,10.8,5.5,20.8c0,10.1-1.9,17.1-5.8,21.2
					C229.8,110.4,223.1,112.4,213.5,112.4z M212.9,69.9c-1.2,0-2,0.1-2.4,0.3v30.6l2.9,0.3c1.5,0,2.7-1.2,3.4-3.6
					c0.8-2.4,1.2-6.6,1.2-12.6c0-6-0.4-10-1.2-12C216,70.9,214.7,69.9,212.9,69.9z"
              />
            </g>
          </g>
        </g>
        <g style={secondaryFillStyle}>
          <g>
            <path
              d="M60.5,169.6c-2,0.2-3.9,0.3-5.9,0.3c-2,0-3.2-0.1-3.7-0.3c-0.5-0.2-0.8-0.5-0.8-0.9v-20.6h-6.7c-0.4,0-0.7-0.4-0.9-1.3
				c-0.3-0.9-0.4-2.2-0.4-4s0.2-3.4,0.6-4.8c0.4-1.4,0.9-2.1,1.5-2.1h25.9c0.5,0,0.8,0.4,1,1.3c0.2,0.9,0.3,2.2,0.3,3.9
				s-0.2,3.3-0.5,4.8c-0.4,1.5-0.8,2.3-1.4,2.3h-6v20.6C63.5,169.1,62.5,169.4,60.5,169.6z"
            />
            <path
              d="M90.5,170.3c-2.7,0-4.9-0.2-6.8-0.7c-1.9-0.5-3.7-1.4-5.3-2.7c-3.2-2.5-4.8-7.4-4.8-14.5c0-3,0.4-5.6,1.3-7.9
				c0.9-2.2,2.1-4,3.7-5.3c3.1-2.6,7-3.8,11.9-3.8c2.5,0,4.7,0.2,6.6,0.7c1.9,0.4,3.6,1.3,5.3,2.4c3.4,2.4,5,7.1,5,13.9
				c0,6.1-1.5,10.7-4.6,13.6C99.8,168.8,95.7,170.3,90.5,170.3z M88.9,162.1c0.4,0.5,0.9,0.7,1.6,0.7s1.2-0.2,1.6-0.7
				c0.4-0.5,0.7-1.5,1-3c0.3-1.5,0.4-3.6,0.4-6.4c0-2.7-0.1-4.8-0.4-6.3c-0.3-1.5-0.6-2.4-1-2.9c-0.4-0.4-0.9-0.7-1.6-0.7
				s-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.4-1,2.9c-0.3,1.5-0.4,3.6-0.4,6.3c0,2.7,0.1,4.8,0.4,6.4C88.2,160.6,88.5,161.6,88.9,162.1z"
            />
            <path
              d="M125.8,169.9h-11.3c-1.6,0-2.6-0.1-3.2-0.3c-0.5-0.2-0.8-0.6-0.8-1.1v-30.3c0-0.8,0.4-1.4,1.1-1.8
				c0.7-0.4,1.9-0.6,3.6-0.6H126c6.1,0,10.4,1.2,12.7,3.6s3.5,6.8,3.5,13.2c0,6.4-1.2,10.8-3.6,13.4
				C136.2,168.6,131.9,169.9,125.8,169.9z M125.5,143c-0.8,0-1.3,0.1-1.5,0.2v19.4l1.8,0.2c1,0,1.7-0.8,2.2-2.3
				c0.5-1.5,0.7-4.2,0.7-8s-0.3-6.3-0.8-7.6C127.4,143.6,126.6,143,125.5,143z"
            />
            <path
              d="M157.4,169.3c-0.9,0.4-2.5,0.5-4.9,0.5s-4.3-0.2-5.9-0.6c-1.6-0.4-2.4-1-2.4-1.7c0-0.8,1.4-5.7,4.3-14.7
				c2.8-9,4.5-14.2,4.9-15.6c0.2-0.4,1.4-0.8,3.9-1c2.4-0.3,5-0.4,7.8-0.4s4.3,0.5,4.6,1.4c6,20.1,9.1,30.3,9.1,30.6s-0.2,0.6-0.6,1
				c-0.9,0.7-2.7,1.1-5.3,1.1c-5.7,0-8.6-0.7-8.7-2l-0.3-3.1h-4.6l-0.2,2.7C158.8,168.4,158.3,169,157.4,169.3z M162.7,155.6
				L162,147h-1.1l-0.8,8.6H162.7z"
            />
            <path
              d="M193.3,169.5c-2,0.3-3.9,0.4-5.8,0.4c-1.9,0-3.2-0.1-3.8-0.3s-0.9-0.6-0.9-1.1v-8.3c-6.3-12.9-9.5-20-9.5-21.1
				c0-0.7,1.1-1.4,3.2-2.1c2.1-0.7,4.4-1.1,6.8-1.1c3,0,4.7,0.9,4.8,2.7l1.3,12.9h0.5l0.9-12.6c0.1-0.9,1.2-1.6,3.4-2.2
				c2.2-0.6,4.3-0.8,6.3-0.8c2,0,3.4,0.3,4.1,0.9s1.2,1.2,1.2,1.8c0,0.6-3.2,7.8-9.6,21.6v8.3C196.2,168.9,195.2,169.2,193.3,169.5z
				"
            />
          </g>
          <g>
            <path
              d="M17.4,119.3c-1.3,0-3.2,0-5.7,0c-2.5,0-4.2-0.2-5-0.5c-0.9-0.4-1.3-0.9-1.3-1.7V67.7c0-1.2,1.6-2.2,4.8-2.9
				c3.2-0.7,6.6-1.1,10.3-1.1c3.7,0,6.3,0.5,7.8,1.4s2.5,1.9,2.7,3l5.3,20.7h0.7l5-19.7c0.4-1.4,2.1-2.6,5.1-3.7
				c3.1-1.1,6.4-1.6,10.1-1.6c3.7,0,6.4,0.3,8.1,0.9c1.7,0.6,2.6,1.3,2.6,2.2v50.2c0,0.7-1.5,1.2-4.5,1.6c-3,0.4-6.6,0.6-10.9,0.6
				c-4.3,0-6.4-0.8-6.4-2.3c0-4.1,0.4-11.5,1.3-22h-0.7l-4.1,20.4c-0.2,0.8-0.8,1.4-1.9,1.7c-1.1,0.4-2.8,0.5-5,0.5
				c-2.2,0-3.6-0.2-4.1-0.5c-0.5-0.3-0.8-0.9-1-1.8l-4.2-20.4h-0.7c0.9,10.7,1.4,18.1,1.4,22C27.2,118.4,23.9,119.2,17.4,119.3z"
            />
            <path
              d="M100.7,120c-4.3,0-8-0.4-11.2-1.2c-3.1-0.8-6-2.3-8.6-4.3c-5.3-4.1-7.9-12-7.9-23.7c0-4.9,0.7-9.2,2.2-12.8
				c1.4-3.7,3.4-6.5,6-8.7c5-4.2,11.5-6.3,19.5-6.3c4.1,0,7.7,0.4,10.8,1.1c3.1,0.7,5.9,2.1,8.6,4c5.5,4,8.2,11.5,8.2,22.7
				c0,10-2.5,17.4-7.4,22.1C115.9,117.7,109.2,120,100.7,120z M98.1,106.6c0.6,0.8,1.5,1.1,2.6,1.1c1.1,0,2-0.4,2.6-1.1
				c0.6-0.8,1.1-2.4,1.6-4.9c0.4-2.5,0.6-5.9,0.6-10.4c0-4.4-0.2-7.9-0.6-10.3c-0.4-2.4-0.9-4-1.6-4.7c-0.6-0.7-1.5-1.1-2.6-1.1
				c-1.1,0-2,0.4-2.6,1.1c-0.6,0.7-1.1,2.3-1.6,4.7c-0.4,2.4-0.6,5.8-0.6,10.3c0,4.4,0.2,7.9,0.6,10.4
				C96.9,104.2,97.5,105.9,98.1,106.6z"
            />
            <path
              d="M159.8,120c-4.3,0-8-0.4-11.2-1.2c-3.1-0.8-6-2.3-8.6-4.3c-5.3-4.1-7.9-12-7.9-23.7c0-4.9,0.7-9.2,2.2-12.8
				c1.4-3.7,3.4-6.5,6-8.7c5-4.2,11.5-6.3,19.5-6.3c4.1,0,7.7,0.4,10.8,1.1c3.1,0.7,5.9,2.1,8.6,4c5.5,4,8.2,11.5,8.2,22.7
				c0,10-2.5,17.4-7.4,22.1C175.1,117.7,168.3,120,159.8,120z M157.2,106.6c0.6,0.8,1.5,1.1,2.6,1.1c1.1,0,2-0.4,2.6-1.1
				c0.6-0.8,1.1-2.4,1.6-4.9c0.4-2.5,0.6-5.9,0.6-10.4c0-4.4-0.2-7.9-0.6-10.3c-0.4-2.4-0.9-4-1.6-4.7c-0.6-0.7-1.5-1.1-2.6-1.1
				c-1.1,0-2,0.4-2.6,1.1c-0.6,0.7-1.1,2.3-1.6,4.7c-0.4,2.4-0.6,5.8-0.6,10.3c0,4.4,0.2,7.9,0.6,10.4
				C156.1,104.2,156.6,105.9,157.2,106.6z"
            />
            <path
              d="M217.5,119.3H199c-2.6,0-4.3-0.2-5.2-0.5c-0.9-0.4-1.3-1-1.3-1.8V67.6c0-1.4,0.6-2.3,1.7-2.9c1.1-0.6,3.1-0.9,5.9-0.9
				h17.6c10,0,16.9,2,20.7,5.9c3.8,3.9,5.7,11.1,5.7,21.5c0,10.4-2,17.7-5.9,21.9C234.3,117.2,227.4,119.3,217.5,119.3z M216.9,75.4
				c-1.3,0-2.1,0.1-2.5,0.3v31.6l3,0.3c1.6,0,2.8-1.3,3.6-3.8c0.8-2.5,1.2-6.8,1.2-13c0-6.2-0.4-10.3-1.3-12.4
				C220,76.5,218.7,75.4,216.9,75.4z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { Logo };
