import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { authReducer } from "./authSlice";
import { emotionsReducer } from "./emotionsSlice";
import { modalReducer } from "./modalSlice";
import { themeReducer } from "./themeSlice";

import { authApi } from "./authApi";
import { journalApi } from "./journalApi";
import { profileApi } from "./profileApi";
import { emotionApi } from "./emotionApi";

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [journalApi.reducerPath]: journalApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [emotionApi.reducerPath]: emotionApi.reducer,
    authReducer,
    emotionsReducer,
    modalReducer,
    themeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      journalApi.middleware,
      profileApi.middleware,
      emotionApi.middleware,
    ),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
