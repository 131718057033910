import { useCallback, useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Emotion as Journal } from "types";
import { Button, Error, Option, Select } from "components";
import { useAppSelector, useAppDispatch } from "store";
import { useGetEmotionsQuery } from "store/emotionApi";
import { journalApi, useCreateJournalMutation } from "store/journalApi";
import { capitalize } from "utils";
import { useSearchParams } from "react-router-dom";

import styles from "./EmotionForm.module.css";

export type EmotionFormValues = Pick<Journal, "emotionId" | "intensity">;

type EmotionFormProps = {
  onSubmit: (values?: EmotionFormValues) => void;
};

const maxEmotionLevel = 10;

const levelOptions: number[] = [...new Array(maxEmotionLevel)].map(
  (_, i) => i + 1,
);

const defaultValues: EmotionFormValues = {
  emotionId: "",
  intensity: 1,
};

const EmotionForm = ({ onSubmit }: EmotionFormProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const date: Date = useMemo(() => {
    const date = searchParams.get("date");
    if (!date) return new Date();
    return new Date(+date);
  }, [searchParams]);

  const {
    isError: isEmotionsError,
    isFetching: isEmotionsFetching,
    isLoading: isEmotionsLoading,
    data: emotions = [],
  } = useGetEmotionsQuery({});

  const [createJournal, { isLoading, isError, isSuccess, data }] =
    useCreateJournalMutation();

  const emotionId = useAppSelector(
    (state) => state.emotionsReducer.selectedEmotionId,
  );

  const selectedEmotion: Journal | undefined = useMemo(() => {
    // TODO: implement this when edit journal functionality will be ready
    // if (!emotionId && journals?.length === 0) return;

    // return journals?.find(({ id }) => id === emotionId);

    return undefined;
  }, [emotions, emotionId]);

  const defaultValuesOverride: EmotionFormValues | undefined = useMemo(() => {
    if (!selectedEmotion || emotionTypes.length === 0) return;
    const { emotionId, intensity } = selectedEmotion;
    return { emotionId, intensity };
  }, [selectedEmotion]);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm<EmotionFormValues>({
    defaultValues: defaultValuesOverride || defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    if (!isLoading && !isError && isSuccess && data) {
      onSubmit();
    }
  }, [isLoading, isError, isSuccess]);

  const emotionTypes = useMemo(
    () =>
      emotions.map(
        ({ name }) =>
          ({
            value: name,
            label: capitalize(name),
          }) as Option,
      ),
    [emotions],
  );

  useEffect(() => {
    if (emotionTypes.length > 0) {
      setValue("emotionId", emotionTypes[0].value as string);
      trigger();
    }
  }, [emotionTypes, setValue, trigger]);

  const onFormSubmit: SubmitHandler<EmotionFormValues> = useCallback(
    (data) => {
      // select returns number as string
      data.intensity = +data.intensity;
      if (selectedEmotion) {
        // we do not have edit request yet
        // TODO: implement edit request when ready here
      } else {
        createJournal({ ...data, createdAt: date.toISOString() });
        // cache clean to force journal list refetch (feel free to provide better solution here)
        dispatch(journalApi.util.resetApiState());
      }
      onSubmit();
    },
    [onSubmit, dispatch, createJournal, selectedEmotion, date],
  );

  const emotionRegister = register("emotionId", {
    required: t("common.error.required"),
  });

  const levelRegister = register("intensity", {
    required: t("common.error.required"),
  });

  return (
    <form className={styles.emotionForm} onSubmit={handleSubmit(onFormSubmit)}>
      <Select
        disabled={isEmotionsLoading}
        options={emotionTypes}
        label={t("emotionForm.emotionLabel")}
        {...emotionRegister}
      />
      <Select
        options={levelOptions}
        label={t("emotionForm.emotionLevelLabel")}
        {...levelRegister}
      />

      <Error>
        {(isError || isEmotionsError) && t("common.error.somethingWrong")}
      </Error>

      <div className={styles.actionsWrapper}>
        <Button
          disabled={!isValid || isLoading || isEmotionsFetching}
          type="submit"
        >
          {t(
            isEmotionsFetching || isLoading
              ? "common.loading"
              : "emotionForm.submit",
          )}
        </Button>
      </div>
    </form>
  );
};

export { EmotionForm };
