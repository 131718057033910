import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import Modal from "react-modal";

import { routes } from "./routes";
import { useAppSelector } from "store";
import { DynamicBackground } from "components";

import themes from "./Themes.module.css";
import styles from "./App.module.css";

function App() {
  const routesElement = useRoutes(routes);
  const theme = useAppSelector((state) => state.themeReducer.theme);

  useEffect(() => {
    Modal.setAppElement(".App");
  }, []);

  return (
    <div className={`App ${styles.App} ${themes[theme]}`}>
      <DynamicBackground />

      {routesElement}
    </div>
  );
}

export default App;
