import { forwardRef, ForwardRefRenderFunction, useState } from "react";

import { Input, InputProps } from "../Input";

import { ReactComponent as EyeSvg } from "svg/eye.svg";
import { ReactComponent as EyeSlashSvg } from "svg/eyeSlash.svg";

type PasswordType = "password" | "text";

type PasswordInputProps = Omit<InputProps, "icon" | "type">;

const PasswordInputComponent: ForwardRefRenderFunction<
  HTMLInputElement,
  PasswordInputProps
> = ({ ...props }, ref) => {
  const [passwordType, setPasswordType] = useState<PasswordType>("password");

  const onEyeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <Input
      Icon={passwordType === "password" ? EyeSvg : EyeSlashSvg}
      onIconClick={onEyeClick}
      type={passwordType}
      ref={ref}
      {...props}
    />
  );
};

const PasswordInput = forwardRef(PasswordInputComponent);

export { PasswordInput };
