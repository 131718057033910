import { getRandomInt } from "utils";

const defaultCirclesSize = 1000000;
const defaultMaxAttempts = 2000000;

export type BackgroundCircle = {
  x: number;
  y: number;
  radius: number;
};

type GetRandomCircleArgs = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  minRadius: number;
  maxRadius: number;
};

const getRandomCircle = ({
  minX,
  maxX,
  minY,
  maxY,
  minRadius,
  maxRadius,
}: GetRandomCircleArgs): BackgroundCircle => {
  const radius = getRandomInt(minRadius, maxRadius);
  const x = getRandomInt(minX - radius, maxX - radius);
  const y = getRandomInt(minY - radius, maxY - radius);

  return { x, y, radius };
};

type GetDistBetweenCircles = (
  circle1: BackgroundCircle,
  circle2: BackgroundCircle,
) => number;

const getDistBetweenCircles: GetDistBetweenCircles = (circle1, circle2) => {
  const distX = circle1.x - circle2.x;
  const distY = circle1.y - circle2.y;
  return Math.hypot(distX, distY) - (circle1.radius + circle2.radius);
};

export type GenerateCirclesArgs = Partial<
  {
    circlesSize: number;
    maxAttempts: number;
  } & GetRandomCircleArgs
>;

const generateCircles = ({
  circlesSize = defaultCirclesSize,
  maxAttempts = defaultMaxAttempts,
  minX = 0,
  maxX = window.innerWidth,
  minY = 0,
  maxY = window.innerHeight,
  minRadius = 45,
  maxRadius = Math.min(window.innerWidth / 1.5, window.innerHeight / 1.5),
}: Partial<GenerateCirclesArgs>): BackgroundCircle[] => {
  const circles: BackgroundCircle[] = [];
  let protection = 0;

  while (circles.length < circlesSize) {
    const newCircle = getRandomCircle({
      minX,
      maxX,
      minY,
      maxY,
      minRadius,
      maxRadius,
    });

    let isOverlapping = false;

    for (let circlesIndex = 0; circlesIndex < circles.length; circlesIndex++) {
      const currentCircle = circles[circlesIndex];
      const dist = getDistBetweenCircles(newCircle, currentCircle);
      const minDist =
        newCircle.radius +
        currentCircle.radius -
        Math.min(newCircle.radius, currentCircle.radius);

      if (dist < minDist) {
        isOverlapping = true;
        break;
      }
    }

    if (!isOverlapping) {
      circles.push(newCircle);
    }

    if (++protection > maxAttempts) break;
  }

  return circles;
};

type GenerateCirclesAsync = (
  circlesSize?: number,
) => Promise<BackgroundCircle[]>;

const generateCirclesAsync: GenerateCirclesAsync = async (circlesSize) => {
  return new Promise((resolve) => {
    resolve(generateCircles({ circlesSize }));
  });
};

export { generateCircles, generateCirclesAsync };
