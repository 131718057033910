import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { openModal } from "store/modalSlice";
import { Button } from "components";

import { ReactComponent as InstagramSvg } from "svg/instagram.svg";
import { ReactComponent as FacebookSvg } from "svg/facebook.svg";

import { ModalId } from "types";

import styles from "./Footer.module.css";

const creationYear = 2022;
const currentYear = new Date().getFullYear();

const iconSize = 35;

const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openContactUsModal = () => {
    dispatch(openModal(ModalId.CONTACT_US));
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerTopWrapper}>
        <div className={styles.iconsWrapper}>
          <a
            aria-label="our facebook"
            href="https://www.facebook.com/profile.php?id=61550355497273"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookSvg width={iconSize} height={iconSize} />
          </a>
          <a
            aria-label="our instagram"
            href="https://www.instagram.com/asa_dev_group/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramSvg width={iconSize} height={iconSize} />
          </a>
        </div>

        <Button
          className={styles.contactUs}
          onClick={openContactUsModal}
          variant="text"
        >
          {t("footer.contactUs")}
        </Button>
      </div>

      <div className={styles.yearsWrapper}>
        <span>
          {creationYear}
          {currentYear > creationYear && ` - ${currentYear}`}
        </span>
      </div>
    </footer>
  );
};

export { Footer };
