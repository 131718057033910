import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { GoDotFill } from "react-icons/go";

import { useAppDispatch, useAppSelector } from "store";
import { useUpdateProfileMutation } from "store/profileApi";
import { Button, Error, Input, Select, Option } from "components";
import { setTheme } from "store/themeSlice";
import { Gender, Locale, User, Theme } from "types";
import { noop } from "utils";

import styles from "./SettingsForm.module.css";

type SettingsFormValues = Pick<
  User,
  "name" | "gender" | "birthday" | "locale" | "theme"
>;

type SettingsFormProps = {
  onSettingsUpdate?: () => void;
};

const SettingsForm = ({ onSettingsUpdate = noop }: SettingsFormProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [updateProfile, { isLoading, isError, data }] =
    useUpdateProfileMutation({
      fixedCacheKey: "updateProfile",
    });
  const { name, gender, locale, theme } = useAppSelector(
    (state) => state.authReducer.user || {},
  ) as Partial<User>;
  const defaultValues: Partial<SettingsFormValues> = {
    name,
    gender,
    theme: theme || Theme.LIGHT,
    locale: locale || i18n.language,
  };
  const {
    register,
    handleSubmit,
    formState: { errors = {}, isValid },
    setValue,
    trigger,
    watch,
  } = useForm<SettingsFormValues>({
    mode: "onBlur",
    defaultValues,
  });

  const genderOptions: Option[] = useMemo(() => {
    return Object.values(Gender).map((gender) => ({
      value: gender,
      label: t(`personGender.${gender}`),
    }));
  }, [t]);

  const onSubmit: SubmitHandler<SettingsFormValues> = (data) => {
    updateProfile(data);
  };

  useEffect(() => {
    trigger();
  }, [t, i18n.language]);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      onSettingsUpdate();
    }
  }, [isLoading, isError, data]);

  const nameRegister = register("name", {
    required: t("common.error.required"),
  });

  const genderRegister = register("gender", {
    required: t("common.error.required"),
  });

  const setFormTheme = useCallback(
    (theme: Theme) => {
      dispatch(setTheme(theme));
      setValue("theme", theme);
    },
    [setValue, dispatch, setTheme],
  );

  const setFormLocale = useCallback(
    (locale: Locale) => {
      i18n.changeLanguage(locale);
      setValue("locale", locale);
    },
    [setValue, i18n],
  );

  const currentTheme = watch("theme", defaultValues.theme);
  const currentLocale = watch("locale", defaultValues.locale);

  return (
    <form className={styles.settingsForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.inputsBlock}>
        <Input
          {...nameRegister}
          isError={!!errors.name?.message}
          errorMessage={errors.name?.message}
          label={t("settingsForm.name")}
          placeholder={t("settingsForm.namePlaceholder")}
          className={styles.input}
          type="text"
        />
        <Select
          {...genderRegister}
          options={genderOptions}
          isError={!!errors.gender?.message}
          errorMessage={errors.gender?.message}
          label={t("settingsForm.gender")}
          placeholder={t("settingsForm.genderPlaceholder")}
          className={styles.input}
        />
      </div>
      <div className={styles.inputsBlock}>
        <div>
          <span>{t("settingsForm.theme")}</span>
          {Object.values(Theme).map((theme) => (
            <Button
              key={theme}
              onClick={(e) => {
                e.preventDefault();
                setFormTheme(theme);
              }}
            >
              {theme} {theme === currentTheme && <GoDotFill size={10} />}
            </Button>
          ))}
        </div>
        <div>
          <span>{t("settingsForm.language")}</span>
          {Object.values(Locale).map((locale) => (
            <Button
              key={locale}
              onClick={(e) => {
                e.preventDefault();
                setFormLocale(locale);
              }}
            >
              {locale} {locale === currentLocale && <GoDotFill size={10} />}
            </Button>
          ))}
        </div>
      </div>

      <Error>{isError && t("common.error.somethingWrong")}</Error>

      <Button type="submit" disabled={!isValid || isLoading}>
        {t(isLoading ? "common.loading" : "settingsForm.save")}
      </Button>
    </form>
  );
};

export { SettingsForm };
