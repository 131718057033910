import { createApi } from "@reduxjs/toolkit/query/react";

import { Emotion } from "types";
import { getCommonBaseQuery } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/journal`;

// TODO: replace Emotion with Journal
type NewJournal = Omit<Emotion, "id" | "createdAt"> & { createdAt?: string };

const journalApi = createApi({
  reducerPath: "journalApi",
  baseQuery: getCommonBaseQuery(baseUrl),
  endpoints: (builder) => ({
    getJournals: builder.query<Emotion[], { from: string; to: string }>({
      query: ({ from, to, ...params }) => ({ url: `/${from}/${to}`, params }),
    }),
    createJournal: builder.mutation<Emotion, NewJournal>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
  }),
});

const { useGetJournalsQuery, useCreateJournalMutation } = journalApi;

export { journalApi, useGetJournalsQuery, useCreateJournalMutation };
