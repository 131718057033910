import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { useSignUpMutation } from "store/authApi";
import { Button, Error, Input, PasswordInput } from "components";
import { emailRegexp, minPasswordLength } from "utils";
import { setModalId } from "store/modalSlice";

import { ModalId } from "types";

import styles from "./RegistrationForm.module.css";

type RegistrationFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

type RegistrationFormProps = {
  onRegistration: () => void;
};

const RegistrationForm = ({ onRegistration }: RegistrationFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [signUp, { isLoading, isError, data }] = useSignUpMutation({
    fixedCacheKey: "signUp",
  });
  const {
    register,
    handleSubmit,
    formState: { errors = {}, isValid },
    setFocus,
    watch,
  } = useForm<RegistrationFormValues>({
    mode: "onBlur",
  });

  useEffect(() => {
    if (!isLoading && !isError && data) {
      onRegistration();
    }
  }, [isLoading, isError, data]);

  const onSubmit: SubmitHandler<RegistrationFormValues> = (data) => {
    signUp(data);
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const emailRegister = register("email", {
    required: t("common.error.required"),
    pattern: {
      value: emailRegexp,
      message: t("common.error.incorrectField"),
    },
  });

  const passwordRegister = register("password", {
    required: t("common.error.required"),
    minLength: {
      value: minPasswordLength,
      message: t("common.error.tooSmallField", { number: minPasswordLength }),
    },
  });

  const confirmPasswordRegister = register("confirmPassword", {
    required: t("common.error.required"),
    minLength: {
      value: minPasswordLength,
      message: t("common.error.tooSmallField", { number: minPasswordLength }),
    },
    validate: (val: string) => {
      if (watch("password") != val) {
        return t("authForms.error.passDoNotMatch");
      }
    },
  });

  const onLoginClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setModalId(ModalId.LOGIN));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...emailRegister}
        isError={!!errors.email?.message}
        errorMessage={errors.email?.message}
        label={t("authForms.email")}
        placeholder={t("authForms.emailPlaceholder")}
        type="text"
        autoComplete="email"
      />

      <PasswordInput
        {...passwordRegister}
        isError={!!errors.password?.message}
        errorMessage={errors.password?.message}
        label={t("authForms.password")}
        placeholder={t("authForms.passwordPlaceholder")}
        autoComplete="new-password"
      />

      <PasswordInput
        {...confirmPasswordRegister}
        isError={!!errors.confirmPassword?.message}
        errorMessage={errors.confirmPassword?.message}
        label={t("authForms.confirmPassword")}
        placeholder={t("authForms.confirmPasswordPlaceholder")}
        autoComplete="new-password"
      />

      <Error>{isError && t("common.error.somethingWrong")}</Error>

      <div className={styles.actionsWrapper}>
        <Button onClick={onLoginClick} variant="outline">
          {t("authForms.login")}
        </Button>

        <Button disabled={!isValid || isLoading} type="submit">
          {t(isLoading ? "common.loading" : "authForms.registration")}
        </Button>
      </div>
    </form>
  );
};

export { RegistrationForm };
