import { ReactNode } from "react";

import {
  ChangePhotoModal,
  ContactUsModal,
  EmotionModal,
  LoginModal,
  RegistrationModal,
  SettingsModal,
} from "./modals";
import { ModalId } from "types";

type Modal = {
  titleKey: string;
  modalBody: ReactNode;
};

type ModalsList = Record<ModalId, Modal>;

// TODO: login and registration modals are really alike
// Think about reusing their code. Maybe, create new AuthModal

export const modalsList: ModalsList = {
  [ModalId.CHANGE_PHOTO]: {
    titleKey: "changePhotoForm.header",
    modalBody: <ChangePhotoModal />,
  },
  [ModalId.CONTACT_US]: {
    titleKey: "contactUsForm.header",
    modalBody: <ContactUsModal />,
  },
  [ModalId.EMOTION_CREATE]: {
    titleKey: "emotionForm.addHeader",
    modalBody: <EmotionModal />,
  },
  [ModalId.EMOTION_EDIT]: {
    titleKey: "emotionForm.editHeader",
    modalBody: <EmotionModal />,
  },
  [ModalId.LOGIN]: {
    titleKey: "authForms.login",
    modalBody: <LoginModal />,
  },
  [ModalId.REGISTRATION]: {
    titleKey: "authForms.registration",
    modalBody: <RegistrationModal />,
  },
  [ModalId.SETTINGS]: {
    titleKey: "settingsForm.header",
    modalBody: <SettingsModal />,
  },
};
