import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store";
import { useSignInMutation } from "store/authApi";
import { Button, Error, Input, PasswordInput } from "components";
import { emailRegexp, minPasswordLength } from "utils";
import { setModalId } from "store/modalSlice";

import { ModalId } from "types";

import styles from "./LoginForm.module.css";

type LoginFormValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onLogIn: () => void;
};

const LoginForm = ({ onLogIn }: LoginFormProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [signIn, { isLoading, isError, data }] = useSignInMutation({
    fixedCacheKey: "signIn",
  });
  const {
    register,
    handleSubmit,
    formState: { errors = {}, isValid },
    setFocus,
  } = useForm<LoginFormValues>({
    mode: "onBlur",
  });

  useEffect(() => {
    if (!isLoading && !isError && data) {
      onLogIn();
    }
  }, [isLoading, isError, data]);

  const onSubmit: SubmitHandler<LoginFormValues> = (data) => {
    signIn(data);
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const emailRegister = register("email", {
    required: t("common.error.required"),
    pattern: {
      value: emailRegexp,
      message: t("common.error.incorrectField"),
    },
  });

  const passwordRegister = register("password", {
    required: t("common.error.required"),
    minLength: {
      value: minPasswordLength,
      message: t("common.error.tooSmallField", { number: minPasswordLength }),
    },
  });

  const onRegistrationClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setModalId(ModalId.REGISTRATION));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...emailRegister}
        isError={!!errors.email?.message}
        errorMessage={errors.email?.message}
        label={t("authForms.email")}
        placeholder={t("authForms.emailPlaceholder")}
        type="text"
      />

      <PasswordInput
        {...passwordRegister}
        isError={!!errors.password?.message}
        errorMessage={errors.password?.message}
        label={t("authForms.password")}
        placeholder={t("authForms.passwordPlaceholder")}
        autoComplete="password"
      />

      <Error>{isError && t("common.error.somethingWrong")}</Error>

      <div className={styles.actionsWrapper}>
        <Button onClick={onRegistrationClick} variant="outline">
          {t("authForms.registration")}
        </Button>

        <Button disabled={!isValid || isLoading} type="submit">
          {t(isLoading ? "common.loading" : "authForms.log-in")}
        </Button>
      </div>
    </form>
  );
};

export { LoginForm };
