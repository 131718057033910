import { useMemo } from "react";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ModalId } from "types";
import { AppRoutes } from "routes";
import { ArrowButton, Button } from "components";
import { EmotionsList, Header } from "./components";
import { useAppDispatch } from "store";
import { selectEmotion } from "store/emotionsSlice";
import { openModal } from "store/modalSlice";

import styles from "./Day.module.css";

const Day = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const date: Date = useMemo(() => {
    const date = searchParams.get("date");
    if (!date) return new Date();
    return new Date(+date);
  }, [searchParams]);

  const isToday: boolean = useMemo(() => {
    return date.toDateString() === new Date().toDateString();
  }, [date]);

  const openEmotionModal = () => {
    dispatch(selectEmotion(null));
    dispatch(openModal(ModalId.EMOTION_CREATE));
  };

  return (
    <div className={styles.dayWrapper}>
      <Header date={date} />

      <div className={styles.actionsWrapper}>
        <Button onClick={() => openEmotionModal()} variant="text">
          {t("emotionForm.addEmotion")}
        </Button>
        <Button variant="text" disabled>
          {t("newEmotionForm.createEmotion")}
        </Button>
      </div>

      <div className={styles.emotionsListWrapper}>
        <ArrowButton
          className={styles.arrowButton}
          direction="left"
          aria-label="previous day"
          onClick={() => {
            const prevDay = new Date(date);

            navigate({
              pathname: AppRoutes.DAY,
              search: createSearchParams({
                date: `${prevDay.setDate(prevDay.getDate() - 1)}`,
              }).toString(),
            });
          }}
        />

        <EmotionsList date={date} />

        <ArrowButton
          className={styles.arrowButton}
          direction="right"
          aria-label="next day"
          onClick={() => {
            const nextDay = new Date(date);

            navigate({
              pathname: AppRoutes.DAY,
              search: createSearchParams({
                date: `${nextDay.setDate(nextDay.getDate() + 1)}`,
              }).toString(),
            });
          }}
          disabled={isToday}
        />
      </div>
    </div>
  );
};

export { Day };
