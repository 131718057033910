import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/query";

import { RootState } from "store";

type GetCommonBaseQuery = (baseUrl: string) => BaseQueryFn;

export const getCommonBaseQuery: GetCommonBaseQuery = (baseUrl) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const access_token = (getState() as RootState).authReducer.access_token;
      if (access_token) {
        headers.set("authorization", `Bearer ${access_token}`);
      }
      return headers;
    },
  });
