import { Link } from "react-router-dom";

import styles from "./Day.module.css";
import { useMemo } from "react";

type DayProps = {
  date: Date;
};

const Day = ({ date }: DayProps) => {
  const isToday = useMemo(() => {
    const now = new Date();
    return date.toDateString() === now.toDateString();
  }, [date]);

  return (
    <Link
      to={`/day?date=${date.valueOf()}`}
      className={`${styles.dayWrapper} ${isToday && styles.today}`}
    >
      <span>{String(date.getDate()).padStart(2, "0")}</span>
    </Link>
  );
};

export { Day };
