import {
  ButtonHTMLAttributes,
  CSSProperties,
  PropsWithChildren,
  useState,
  useEffect,
  useRef,
} from "react";

import { useAppSelector } from "store";
import { setOpacity } from "utils";

import themes from "Themes.module.css";
import styles from "./Button.module.css";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "text" | "outline" | "contained";
};

const Button = ({
  children,
  variant = "contained",
  className,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const ref = useRef<HTMLButtonElement>(null);
  const theme = useAppSelector((state) => state.themeReducer.theme);

  const [outlineActiveBackColor, setOutlineActiveBackColor] =
    useState<string>("transparent");

  // we use async function here to be sure that it will work after component render
  const updateOutlineActiveBackColor = async () => {
    if (variant === "contained" || !ref.current) return "";

    const color = getComputedStyle(ref.current).getPropertyValue(
      "--button-color",
    );

    setOutlineActiveBackColor(setOpacity(color, 0.5));
  };

  useEffect(() => {
    updateOutlineActiveBackColor();
  }, [variant, theme]);

  return (
    <button
      className={`
        ${themes[theme]} 
        ${styles.button} 
        ${styles[variant]}
        ${className}
      `}
      style={
        {
          "--button-outline-active-back-color": outlineActiveBackColor,
        } as CSSProperties
      }
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
};

export { Button };
