import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AppRoutes } from "routes";
import { useAppDispatch } from "store";
import { openModal } from "store/modalSlice";

import { ModalId } from "types";

import styles from "./NotFound.module.css";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openContactUsModal = () => {
    dispatch(openModal(ModalId.CONTACT_US));
  };

  const goHome = () => {
    navigate(AppRoutes.HOME);
  };

  return (
    <div>
      <h1>404</h1>
      <p>{t("notFound.oops")}</p>
      <p>
        {t("notFound.tryTo")}{" "}
        <a aria-label="home" className={styles.link} onClick={goHome}>
          {t("notFound.goThere")}
        </a>{" "}
        {t("notFound.or")}{" "}
        <a
          aria-label="contact us"
          className={styles.link}
          onClick={openContactUsModal}
        >
          {t("notFound.contactUs")}
        </a>
      </p>
    </div>
  );
};

export { NotFound };
