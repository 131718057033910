import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  BackgroundCircle,
  generateCirclesAsync,
  GenerateCirclesArgs,
} from "services";

import animations from "Animations.module.css";
import styles from "./DynamicBackground.module.css";

const generateCirclesWorkerArgs: GenerateCirclesArgs = {
  maxX: window.innerWidth,
  maxY: window.innerHeight,
  maxRadius: Math.min(window.innerWidth / 1.75, window.innerHeight / 1.75),
};

const DynamicBackground = () => {
  const location = useLocation();
  const [circles, setCircles] = useState<BackgroundCircle[]>([]);

  const generateCirclesWorker = useMemo(
    () => new Worker(new URL("./GenerateCirclesWorker.ts", import.meta.url)),
    [],
  );

  useEffect(() => {
    if (window.Worker) {
      generateCirclesWorker.postMessage(generateCirclesWorkerArgs);

      generateCirclesWorker.onmessage = (
        e: MessageEvent<BackgroundCircle[]>,
      ) => {
        setCircles(e.data);
      };
    } else {
      generateCirclesAsync().then((circles) => setCircles(circles));
    }
  }, [generateCirclesWorker, location]);

  return (
    <div className={styles.circlesWrapper}>
      {circles.map(({ x, y, radius }, i) => (
        <div
          key={i}
          className={`${styles.circle} ${animations.fadeIn}`}
          style={{
            left: `${x}px`,
            top: `${y}px`,
            width: `${radius * 2}px`,
            height: `${radius * 2}px`,
          }}
        />
      ))}
    </div>
  );
};

export { DynamicBackground };
