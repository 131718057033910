import {
  ChangeEventHandler,
  forwardRef,
  ForwardRefRenderFunction,
  TextareaHTMLAttributes,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { noop } from "utils";
import { Error } from "../Error";
import { FieldProps } from "../types";

import styles from "../Form.module.css";

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & FieldProps;

const TextareaComponent: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextareaProps
> = (
  {
    label,
    isError,
    errorMessage,
    isErrorPlaceholder = true,
    onChange = noop,
    className,
    ...textareaProps
  },
  forwardedRef,
) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useRef<HTMLTextAreaElement>(null);

  // to be able to use ref with forwardedRef
  useImperativeHandle(forwardedRef, () => ref.current as HTMLTextAreaElement);

  useEffect(() => {
    setIsActive(!!ref.current?.value);
  }, [ref]);

  const onTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setIsActive(!!e.target.value);

    onChange(e);
  };

  return (
    <div className={className}>
      <label
        className={`
          ${styles.label} 
          ${isError && styles.errorLabel}
          ${isActive && styles.activeLabel}
      `}
      >
        <span
          className={`
            ${styles.labelText} 
            ${isError && styles.errorLabelText}
            `}
        >
          {label}
        </span>
        <textarea
          ref={ref}
          className={styles.input}
          onChange={onTextareaChange}
          {...textareaProps}
        />
      </label>
      {isErrorPlaceholder && <Error>{isError && errorMessage}</Error>}
    </div>
  );
};

const Textarea = forwardRef(TextareaComponent);

export { Textarea };
