import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// `selectedEmotionId` is to store emotionId that we want to edit to allow edit modal to fetch it
type EmotionsState = {
  selectedEmotionId: string | null;
};

const initialState: EmotionsState = {
  selectedEmotionId: null,
};

const emotionsSlice = createSlice({
  name: "emotions",
  initialState,
  reducers: {
    selectEmotion: (state, action: PayloadAction<string | null>) => {
      state.selectedEmotionId = action.payload;
    },
  },
});

const emotionsReducer = emotionsSlice.reducer;
const { selectEmotion } = emotionsSlice.actions;

export { emotionsReducer, selectEmotion };
