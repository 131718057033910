export enum Gender {
  MALE = "male",
  FEMALE = "female",
  OTHER = "other",
}

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

export enum Locale {
  EN = "en",
  UA = "ua",
}

export type Emotion = {
  id: string;
  emotionId: string;
  intensity: number;
  createdAt: string;
};

export enum ModalId {
  CHANGE_PHOTO = "changePhoto",
  CONTACT_US = "contactUs",
  EMOTION_CREATE = "emotionCreate",
  EMOTION_EDIT = "emotionEdit",
  LOGIN = "login",
  REGISTRATION = "registration",
  SETTINGS = "settings",
}

export enum LocalStorageKey {
  ACCESS_TOKEN = "accessToken",
  USER = "user",
}

// TODO: we need to add email and photoUrl in BE
export type User = {
  id: string;
  email?: string;
  name: string;
  theme: string;
  phone: string;
  locale: string;
  gender: string;
  birthday: string;
  photoUrl?: string;
};
