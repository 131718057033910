import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "store";
import { openModal } from "store/modalSlice";
import { getAge } from "utils";

import { ReactComponent as PencilSvg } from "svg/pencil.svg";
import { ReactComponent as AccountSvg } from "svg/account.svg";

import { ModalId } from "types";

import styles from "./UserInfo.module.css";

const svgSize = 125;

const UserInfo = () => {
  const { t } = useTranslation();
  const { name, birthday, gender, email, photoUrl } =
    useAppSelector((state) => state.authReducer.user) || {};
  const dispatch = useAppDispatch();

  const openEditPhotoModal = useCallback(() => {
    dispatch(openModal(ModalId.CHANGE_PHOTO));
  }, [dispatch, openModal]);

  const age: number | null = useMemo(() => {
    if (!birthday) return null;

    const age = getAge(birthday);
    if (!age || isNaN(age)) return null;
    return age;
  }, [birthday]);

  return (
    <div className={styles.userInfoWrapper}>
      <div className={styles.userInfo}>
        <h4 className={styles.username}>{name}</h4>

        <ul>
          {age && (
            <li>
              {age} {t("userInfo.years")}
            </li>
          )}
          {gender && <li>{t(`personGender.${gender}`)}</li>}
          {email && <li>{email}</li>}
        </ul>
      </div>

      <div className={styles.userPhotoWrapper}>
        <div
          style={photoUrl ? { backgroundImage: `url(${photoUrl})` } : {}}
          className={styles.userPhoto}
        >
          {!photoUrl && (
            <AccountSvg
              className={styles.photoPlaceholder}
              width={svgSize}
              height={svgSize}
            />
          )}
          <button
            aria-label="edit photo"
            className={styles.editPhotoButton}
            onClick={openEditPhotoModal}
          >
            <PencilSvg width={20} height={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export { UserInfo };
