// import { AiFillGoogleCircle } from "react-icons/ai";
// import { MdFacebook } from "react-icons/md";

import { useAppDispatch } from "store";
import { closeModal } from "store/modalSlice";
import { LoginForm } from "./components";

// import styles from "./LoginModal.module.css";

const LoginModal = () => {
  const dispatch = useAppDispatch();

  const onLogIn = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <LoginForm onLogIn={onLogIn} />

      {/* <div className={styles.socialMediaWrapper}>
        <AiFillGoogleCircle size={100} />
        <MdFacebook size={100} />
      </div> */}
    </>
  );
};

export { LoginModal };
