import type { RouteObject } from "react-router-dom";

import { Layout } from "./Layout";
import { PersonalAccount } from "./PersonalAccount";
import { Home } from "./Home";
import { Calendar } from "./Calendar";
import { Day } from "./Day";
import { Diagrams } from "./Diagrams";
import { NotFound } from "./NotFound";

enum AppRoutes {
  HOME = "/",
  ACCOUNT = "/personal-account",
  CALENDAR = "/calendar",
  DAY = "/day",
  DIAGRAMS = "/diagrams",
  NOT_FOUND = "*",
}

const routes: RouteObject[] = [
  {
    path: AppRoutes.HOME,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: AppRoutes.CALENDAR,
        element: <Calendar />,
      },
      {
        path: AppRoutes.DAY,
        element: <Day />,
      },
      {
        path: AppRoutes.NOT_FOUND,
        element: <NotFound />,
      },
    ],
  },
  {
    // protected routes
    path: AppRoutes.HOME,
    element: <Layout isProtected />,
    children: [
      {
        path: AppRoutes.ACCOUNT,
        element: <PersonalAccount />,
      },
      {
        path: AppRoutes.DIAGRAMS,
        element: <Diagrams />,
      },
    ],
  },
];

export { AppRoutes, routes };
