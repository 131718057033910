// import { AiFillGoogleCircle } from "react-icons/ai";
// import { MdFacebook } from "react-icons/md";

import { useAppDispatch } from "store";
import { closeModal } from "store/modalSlice";
import { RegistrationForm } from "./components";

// import styles from "./RegistrationModal.module.css";

const RegistrationModal = () => {
  const dispatch = useAppDispatch();

  const onRegistration = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <RegistrationForm onRegistration={onRegistration} />

      {/* <div className={styles.socialMediaWrapper}>
        <AiFillGoogleCircle size={100} />
        <MdFacebook size={100} />
      </div> */}
    </>
  );
};

export { RegistrationModal };
