import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { LocalStorageKey, User } from "types";

type AuthState = {
  user?: User | null;
  access_token?: string | null;
};

const initialState: AuthState = {
  user: null,
  access_token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: () => {
    const user = localStorage.getItem(LocalStorageKey.USER);
    const access_token = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);

    if (!user || !access_token) return { ...initialState };

    const resState: AuthState = {
      ...initialState,
      access_token,
    };

    try {
      resState.user = user && JSON.parse(user);
    } catch (error) {
      resState.access_token = initialState.access_token;
    }

    return resState;
  },
  reducers: {
    login: (
      state,
      {
        payload: { user, access_token },
      }: PayloadAction<{ user: User; access_token: string }>,
    ) => {
      // TODO: maybe move it to a separate, local storage middleware
      // but this do not change app state so should be ok for now
      localStorage.setItem(LocalStorageKey.USER, JSON.stringify(user));
      localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, access_token);

      state.user = user;
      state.access_token = access_token;
    },
    logout: (state) => {
      localStorage.removeItem(LocalStorageKey.USER);
      localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);

      state.user = null;
      state.access_token = null;
    },
    setProfile: (state, { payload: user }: PayloadAction<User>) => {
      if (!user) return;

      localStorage.setItem(LocalStorageKey.USER, JSON.stringify(user));

      state.user = user;
    },
  },
});

const authReducer = authSlice.reducer;
const { login, logout, setProfile } = authSlice.actions;

export { authReducer, login, logout, setProfile };
