import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { login } from "./authSlice";
import { User } from "types";

type AuthRequestBody = {
  email: string;
  password: string;
};

type AuthResponseBody = {
  user: User;
  access_token: string;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/auth/`;

// TODO: figure out something with "onQueryStarted" and how to reuse it
const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    signIn: builder.mutation<AuthResponseBody, AuthRequestBody>({
      query: (body) => ({
        url: "sign-in/email",
        method: "POST",
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
    signUp: builder.mutation<AuthResponseBody, AuthRequestBody>({
      query: (body) => ({
        url: "sign-up/email",
        method: "POST",
        body,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(login(data));
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

const { useSignInMutation, useSignUpMutation } = authApi;

export { authApi, useSignInMutation, useSignUpMutation };
