import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { User } from "types";
import { getCommonBaseQuery } from "utils";

const baseUrl = `${process.env.REACT_APP_API_URL}/emotion`;

type GetEmotionsRequestParams = {
  skip?: number;
  take?: number;
  cursor?: number;
};

type Emotion = {
  name: string;
  family: string;
  description: string;
  score: number;
};

type NewEmotion = Omit<Emotion, "family" | "description">;

const emotionApi = createApi({
  reducerPath: "emotionApi",
  baseQuery: getCommonBaseQuery(baseUrl),
  endpoints: (builder) => ({
    getEmotions: builder.query<Emotion[], GetEmotionsRequestParams>({
      query: (params) => ({ url: "", params }),
    }),
    createEmotion: builder.mutation<User, NewEmotion>({
      query: (body) => ({
        url: "",
        method: "PUT",
        body,
      }),
    }),
  }),
});

const { useGetEmotionsQuery, useCreateEmotionMutation } = emotionApi;

export { emotionApi, useGetEmotionsQuery, useCreateEmotionMutation };
