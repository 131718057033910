import React, { PropsWithChildren } from "react";

import { useAppSelector } from "store";

import themes from "Themes.module.css";
import styles from "./Paper.module.css";

type PaperProps = PropsWithChildren & React.HTMLAttributes<HTMLDivElement>;

const Paper = ({ children, className }: PaperProps) => {
  const theme = useAppSelector((state) => state.themeReducer.theme);

  return (
    <div className={`${themes[theme]} ${styles.paper} ${className}`}>
      {children}
    </div>
  );
};

export { Paper };
