import { useAppDispatch } from "store";
import { closeModal } from "store/modalSlice";

import { SettingsForm } from "./components";

const SettingsModal = () => {
  const dispatch = useAppDispatch();

  const onSettingsUpdate = () => {
    dispatch(closeModal());
  };

  return <SettingsForm onSettingsUpdate={onSettingsUpdate} />;
};

export { SettingsModal };
