import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "store";
import { openModal } from "store/modalSlice";
import { AppRoutes } from "routes";
import { Button, Logo } from "components";
import { ModalId } from "types";

import animations from "Animations.module.css";
import styles from "./Home.module.css";

const Home = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.authReducer.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={styles.homeWrapper}>
      <Logo width="250" height="250" className={animations.fadeIn} />

      <nav className={styles.navigation}>
        <Button
          className={styles.homeButton}
          onClick={() => navigate(AppRoutes.CALENDAR)}
          variant="text"
        >
          {t("appTitles.calendar")}
        </Button>
        <Button
          className={styles.homeButton}
          onClick={() => navigate(AppRoutes.DAY)}
          variant="text"
        >
          {t("appTitles.day")}
        </Button>
        {!user && (
          <Button
            className={styles.homeButton}
            onClick={() => dispatch(openModal(ModalId.LOGIN))}
            variant="text"
          >
            {t("authForms.login")}
          </Button>
        )}
        {user && (
          <Button
            className={styles.homeButton}
            onClick={() => navigate(AppRoutes.DIAGRAMS)}
            variant="text"
          >
            {t("appTitles.diagrams")}
          </Button>
        )}
      </nav>
    </div>
  );
};

export { Home };
