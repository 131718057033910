import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button, Input, Textarea } from "components";
import { emailRegexp } from "utils";

import styles from "./ContactUsForm.module.css";

type ContactUsFormValues = {
  email: string;
  message: string;
};

const minMessageLength = 20;
const maxMessageLength = 200;

const ContactUsForm = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors = {}, isValid },
    setFocus,
  } = useForm<ContactUsFormValues>({
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<ContactUsFormValues> = (data) => {
    console.log(data);
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const emailRegister = register("email", {
    required: t("common.error.required"),
    pattern: {
      value: emailRegexp,
      message: t("common.error.incorrectField"),
    },
  });

  const messageRegister = register("message", {
    required: t("common.error.required"),
    minLength: {
      value: minMessageLength,
      message: t("common.error.tooSmallField", { number: minMessageLength }),
    },
    maxLength: {
      value: maxMessageLength,
      message: t("common.error.tooBigField", { number: maxMessageLength }),
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Input
        {...emailRegister}
        isError={!!errors.email?.message}
        errorMessage={errors.email?.message}
        label={t("contactUsForm.email")}
        placeholder={t("contactUsForm.emailPlaceholder")}
        type="text"
      />

      <Textarea
        {...messageRegister}
        isError={!!errors.message?.message}
        errorMessage={errors.message?.message}
        label={t("contactUsForm.message")}
        placeholder={t("contactUsForm.messagePlaceholder")}
        rows={4}
      />

      <div className={styles.actionsWrapper}>
        <Button disabled={!isValid} type="submit">
          {t("contactUsForm.send")}
        </Button>
      </div>
    </form>
  );
};

export { ContactUsForm };
