import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "store";
import { logout } from "store/authSlice";
import { authApi } from "store/authApi";
import { openModal } from "store/modalSlice";
import { useGetProfileQuery } from "store/profileApi";
import { AppRoutes } from "routes";
import { Button } from "components";
import { UserInfo } from "./components";

import { ModalId } from "types";

import styles from "./PersonalAccount.module.css";

const PersonalAccount = () => {
  const { t } = useTranslation();
  const { isFetching } = useGetProfileQuery();
  const user = useAppSelector((state) => state.authReducer.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    dispatch(logout());
    dispatch(authApi.util.resetApiState());
  };

  return (
    <>
      {isFetching && <span>{t("common.loading")}</span>}
      <UserInfo />
      <div className={styles.navigation}>
        <Button
          className={styles.navigationButton}
          onClick={() => navigate(AppRoutes.CALENDAR)}
          variant="text"
        >
          {t("appTitles.calendar")}
        </Button>
        <Button
          className={styles.navigationButton}
          onClick={() => navigate(AppRoutes.DAY)}
          variant="text"
        >
          {t("appTitles.day")}
        </Button>
      </div>
      <div className={styles.actions}>
        {user && <Button onClick={logoutUser}>{t("authForms.logout")}</Button>}

        <Button onClick={() => dispatch(openModal(ModalId.SETTINGS))}>
          Settings
        </Button>
      </div>
    </>
  );
};

export { PersonalAccount };
