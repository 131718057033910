import { useAppDispatch } from "store";
import { closeModal } from "store/modalSlice";
import { EmotionForm } from "./components";

const EmotionModal = () => {
  const dispatch = useAppDispatch();

  const onEmotionFormSubmit = () => {
    dispatch(closeModal());
  };

  return <EmotionForm onSubmit={onEmotionFormSubmit} />;
};

export { EmotionModal };
